<template>
  <div id="app">
    <div class="msg-wrapper">
      <p class="note">This page is best experienced on a Mobile Device.</p>
    </div>
    <section class="pg-wrapper">
      <!-- <template> -->
      <navbar v-if="$route.name === 'Code Collection' ||
      $route.name === 'Redeem Instruction'" @disable-body="disableScreen"></navbar>
      <!-- </template> -->
      <div>
        <div id="router-view"></div>
        <router-view class="router-view" />
      </div>
    </section>
  </div>
</template>
<script>
import Navbar from '@/components/navbar.vue';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
    };
  },
  created() {
  },
  components: {
    Navbar,
  },
  computed: {
    ...mapGetters({
      vg_user: 'monster/user',
      vg_token: 'monster/token',
    }),
    displayNavs() {
      return this.isLoggedIn && !this.hideNav;
    },
    isLoggedIn() {
      const token = this.vg_token;
      if (!token || token === undefined || token === '') {
        return false;
      }
      return true;
    },
    hideNav() {
      return (
        this.$route.name === 'Login'
        || this.$route.name === 'Signup'
        || this.$route.name === 'English'
        || this.$route.name === 'French'
      );
    },
  },
  methods: {
    disableScreen() {
      // creates <div class="overlay"></div> and
      // adds it to the DOM
      // const div = document.createElement('div');
      // div.className += 'overlay';
      // document.body.appendChild(div);

      const router = document.getElementById('router-view');
      router.classList.toggle('overlay');
    },
  },
};
</script>

<style lang="scss">
.error {
  color: rgba(240, 89, 97, 0.945);
}

.inputError {
  border: 2px solid rgba(248, 36, 47, 0.945);
  border-radius: 4px;
}

.success {
  color: #7eb436;
}

.grid-flex {
  display: grid;
  grid-template-columns: auto auto;
}

.pointer {
  cursor: pointer;
}

.shakeanimation-enter-active {
  animation: shake 1s;
}
.shakeanimation-leave-active {
  animation: shake 0s;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.spinner {
  -webkit-animation: rotation 0.7s infinite linear;
  animation: rotation 0.7s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.relative {
  position: relative;
}
.fullscreen {
  height: 100%;
}
.bottom {
  position: absolute;
  bottom: 4vh;
}

.defaultCase {
  text-transform: none !important;
}
.hide {
  visibility: hidden;
}
.navbar-menu {
  padding-top: 1.1rem !important;
}
.overlay {
  background-color: #efefef;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 11;
  top: 0px;
  left: 0px;
  opacity: 0; /* in FireFox */
  filter: alpha(opacity=0); /* in IE */
}

.logo_image {
  padding: 100px 15px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  margin: 0 0 40px 0;
}

.logo_image::after {
  content: "";
  width: 80%;
  height: 6px;
  background-image: -webkit-gradient(
      linear,
      right top,
      left top,
      from(rgba(100, 164, 1, 0)),
      color-stop(50%, #b3d131),
      color-stop(1%, rgba(100, 164, 1, 0))
    ),
    -webkit-gradient(linear, left top, right top,
    from(rgba(100, 164, 1, 0)), color-stop(50%, #b3d131), color-stop(1%, rgba(100, 164, 1, 0)));
  background-image: -webkit-linear-gradient(
      right,
      rgba(100, 164, 1, 0),
      #b3d131 50%,
      rgba(100, 164, 1, 0) 1%
    ),
    -webkit-linear-gradient(left, rgba(100, 164, 1, 0), #b3d131 50%, rgba(
            100,
            164,
            1,
            0
          )
          1%);
  background-image: -o-linear-gradient(
      right,
      rgba(100, 164, 1, 0),
      #b3d131 50%,
      rgba(100, 164, 1, 0) 1%
    ),
    -o-linear-gradient(left, rgba(100, 164, 1, 0), #b3d131 50%, rgba(
            100,
            164,
            1,
            0
          )
          1%);
  background-image: linear-gradient(
      to left,
      rgba(100, 164, 1, 0),
      #b3d131 50%,
      rgba(100, 164, 1, 0) 1%
    ),
    linear-gradient(
      to right,
      rgba(100, 164, 1, 0),
      #b3d131 50%,
      rgba(100, 164, 1, 0) 1%
    );
  position: absolute;
  bottom: 0px;
}
</style>
