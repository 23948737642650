import Vue from 'vue';
import Vuelidate from 'vuelidate';
import _ from 'lodash';

// styles
import '@fortawesome/fontawesome-free/css/all.css';

import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/style.css';
import i18n from './i18n';

Object.defineProperty(Vue.prototype, '$_', { value: _ });

Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
