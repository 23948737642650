import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';


// Not logged in pages
import Login from '@/pages/login.vue';
import CodeVerification from '@/pages/codeVerification.vue';
// Logged in pages
import CodeCollection from '@/pages/LoggedIn Pages/codeCollection.vue';
import HowToRedeem from '@/pages/LoggedIn Pages/howToRedeem.vue';

// middlewares
import redirect from '@/router/middlewares/redirect';
import auth from '@/router/middlewares/auth';
import middlewarePipeline from './middlewarepipeline';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: 'Redeem Instruction' },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      middleware: [redirect],
    },
  },
  {
    path: '/login/verify',
    name: 'Verify Code',
    component: CodeVerification,
    meta: {
      middleware: [redirect],
    },
  },
  {
    path: '/how-to-redeem',
    name: 'Redeem Instruction',
    component: HowToRedeem,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/my-collection',
    name: 'Code Collection',
    component: CodeCollection,
    meta: {
      middleware: [auth],
    },
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware || to.meta.middleware.length <= 0) {
    return next();
  }
  const { middleware } = to.meta;
  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
