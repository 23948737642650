<template>
  <div>
    <div class="msg-wrapper">
      <p class="note">{{ $t("desktop_best_experienced_on_mobile") }}</p>
    </div>
    <section class="pg-wrapper">
      <header
        class="main-header mb40"
        :class="{
          'has-no-strip justify-content-between align-items-center':
            $route.name === 'Landing Page',
        }"
      >
        <!-- Navigation Start -->
        <nav
          class="main-menu navbar"
          v-if="
            $route.name === 'Landing Page' ||
            $route.name === 'Redeem Instruction' ||
            $route.name === 'Code Collection'
          "
        >
          <div class="navbar-brand" @click.prevent="switchNav">
            <span
              class="navbar-burger burger"
              :class="{ 'is-active': navStatus }"
              data-target="navbarMenuHeroA"
            >
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div
            id="navbarMenuHeroA"
            class="navbar-menu"
            :class="{ 'is-active': navStatus }"
          >
            <div class="navbar-end fullscreen relative">
              <div class="navbar-brand mb20" @click.prevent="switchNav">
                <span
                  class="navbar-burger burger"
                  :class="{ 'is-active': navStatus }"
                  data-target="navbarMenuHeroA"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </div>
              <!-- <span
                v-for="path in paths"
                :key="path.text"
                @click="changePath(path)"
                :class="{'navbar-item': true,
                'is-active': $route.name === path.name,
                'pointer': true}"
              >{{$t(path.text)}}</span> -->

              <span
                class="navbar-item mb10 pointer"
                :class="{ 'is-active': $route.name === 'Redeem Instruction' }"
                @click="() => changePath('Redeem Instruction')"
              >
                {{ $t("how_to_redeem_text") }}</span
              >

              <span @click="changeLocale" class="navbar-item mb10 pointer">
                {{ $t("nav_change_language") }}</span
              >
              <span class="navbar-item pointer" @click="logout">
                {{ $t("nav_logout_btn") }}
              </span>
            </div>
          </div>
        </nav>

        <!-- <div class="back-link" v-else @click="goBack">
          <span class="navbar-burger burger">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>-->

        <!-- Navigation End -->
        <div class="pg-title" v-if="$route.name === 'Code Collection'">
          <h1>{{ $t("nav_your_codes") }}</h1>
        </div>
        <div class="pg-title" v-if="$route.name === 'Redeem Instruction'">
          <h1>{{ $t("how_to_redeem_text") }}</h1>
        </div>
      </header>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

function initialState() {
  return {
    navStatus: false,
    paths: [],
  };
}
export default {
  data() {
    return initialState();
  },
  async mounted() {
    // await this.fetchProfile();
  },
  methods: {
    ...mapActions({
      va_getProfile: 'monster/GetProfile',
      va_logout: 'monster/Logout',
      va_changeLocale: 'localization/changeLocale',
    }),
    switchNav() {
      this.navStatus = !this.navStatus;
      this.$emit('disable-body');
    },
    goBack() {
      this.$router.go(-1);
    },
    changePath(path) {
      this.switchNav();
      this.$router.push({ name: path }).catch((error) => {
        console.log('you are already in this page');
      });
    },
    async logout() {
      try {
        this.va_logout();
      } catch (err) {
        console.log(err);
      }
      try {
        this.$store.commit('clear_state');
        this.$router.push({ name: 'Login' });
      } catch (err) {
        console.log(err);
      }
      this.$emit('disable-body');
    },
    changeLocale() {
      let newLocale;
      if (this.vg_locale === 'en') {
        newLocale = 'fr';
        this.va_changeLocale(newLocale);
      } else if (this.vg_locale === 'fr') {
        newLocale = 'en';
        this.va_changeLocale(newLocale);
      }
      this.switchNav();
    },
  },
  computed: {
    ...mapGetters({
      vg_locale: 'localization/selectedLocale',
    }),
  },
};
</script>

<style>
</style>
