/* eslint-disable indent */

export default function ({ next, store }) {
    const user = store.getters['monster/token'];

    const notLoggedIn = !user || user === undefined || user === null;
    if (!notLoggedIn) {
        return next({
            name: 'Code Collection',
        });
    }
    return next();
}
