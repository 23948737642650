/* eslint-disable indent */
import axios from 'axios';
import { URL, apiToken } from './localization.env';

const localizationAxiosInstaceV1 = axios.create({
    baseURL: `${URL}/api/v1`,
    headers: {
        'api-token': apiToken,
    },
});

export default {
    /**
   * Get all the available locale
   * @returns {Promise}
   */
    getAllLocales() {
        return localizationAxiosInstaceV1({
            method: 'get',
            url: '/clients/locales',
        });
    },

    /**
   * Get all the available locale
   * @returns {Promise}
   */
    getAllTranslations() {
        return localizationAxiosInstaceV1({
            method: 'get',
            url: '/clients/translations?platform=web',
        });
    },
};
