/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

/* eslint-disable indent */
import i18n from '@/i18n';
import LocalizationApi from '@/clients/localization/localization.api';


export default {
    changeLocale({ commit }, newLocale) {
        i18n.locale = newLocale; // important!
        commit('updateLocale', newLocale);
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    },
    async getLocales({ commit }) {
        const response = await LocalizationApi.getAllLocales();
        commit('setLocales', response.data.payload);
        // use the following if we want to set the locale using the is_default value
        // const a = Object.values(response.data.payload).find((el) => el.is_default === true);
        // const locale = a.language_code.split('-')[0];
        // i18n.locale = locale; // important!
        // commit('updateLocale', locale);
    },
    async getAllTranslations({ commit }) {
        const response = await LocalizationApi.getAllTranslations();
        if (response.data.payload) {
            for (const i in response.data.payload) {
                const key = i.split('_')[0];
                delete Object.assign(response.data.payload, { [key]: response.data.payload[i] })[i];
            }
        }
        commit('setTranslations', response.data.payload);
    },
};
