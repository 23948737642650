<template>
  <div>
    <div class="msg-wrapper">
      <p class="note">{{$t('desktop_best_experienced_on_mobile')}}</p>
    </div>
    <section class="pg-wrapper">
      <div>

          <div class="logo_image">
          <img src="@/assets/img/monster-energy-logo.png"
          alt="Monster Energy Logo" class="mb40 image"/>
        </div>

      </div>
      <!-- main content start -->
      <div class="main-content-wrapper">
        <!-- container start-->
        <div class="container">
          <div class="columns">
            <div class="column">
              <!-- Login Form Start -->
              <div class="wrapper">
                <div class="block-title mb20">
                  {{$t('verification_code_header')}}
                </div>
                <div class="field mb20">
                  <div class="control">
                    <!-- add has-value class to input for label effect -->
                    <input
                      ref="code"
                      @blur="$v.verificationCode.$touch()"
                      required
                      v-model="verificationCode"
                      type="number"
                      placeholder
                      :class="{'input': true,
                      'defaultCase': true,
                      'has-value': verificationCode
                      }"
                      @keypress.enter="verify()"
                    />
                    <label class="label">
                      {{$t('verification_code')}}
                      <span class="hide">r</span>
                    </label>
                  </div>
                  <div>
                    <transition name>
                      <template v-if="$v.verificationCode.$dirty">
                        <small
                          v-if="!$v.verificationCode.required"
                          :class="{'error': true}"
                        >{{$t('verificationCode_required')}}</small>
                      </template>
                    </transition>
                  </div>
                </div>
                <div>
                  <small class="error">{{error.toUpperCase()}}</small>
                </div>

                <span
                  class="button is-primary mb20"
                  :class="{'is-loading': loading}"
                  @click="verify()"
                >{{$t('verify_button')}}</span>
              </div>
              <!-- Login Form End -->
            </div>
          </div>
        </div>
        <!-- container end-->
      </div>
      <!-- main content end -->
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

function initialState() {
  return {
    verificationCode: '',
    error: '',
    loading: false,
  };
}
export default {
  data() {
    return initialState();
  },

  validations: {
    verificationCode: {
      required,
    },
  },

  computed: {
    ...mapGetters({
      vg_locale: 'localization/selectedLocale',
    }),

    fieldEmpty() {
      if (!this.$v.verificationCode.required) {
        return true;
      }
      return false;
    },
  },

  watch: {
    fieldEmpty() {
      if (this.fieldEmpty === true) {
        this.error = '';
      }
    },
  },

  mounted() {
    this.$refs.code.focus();
  },

  methods: {
    ...mapActions({
      verifyCode: 'monster/VerifyAccessCode',
    }),
    async verify() {
      this.loading = true;
      if (!this.$v.$invalid) {
        try {
          const data = {
            access_code: this.verificationCode,
          };
          await this.verifyCode(data);
          this.$router.push({ name: 'Redeem Instruction' });
        } catch (error) {
          this.error = error.response.data.msg;
        }
      } else {
        this.$v.verificationCode.$touch();
      }
      this.loading = false;
    },

    changeLocale() {
      let newLocale;
      if (this.vg_locale === 'en') {
        newLocale = 'fr';
        this.va_changeLocale(newLocale);
      } else if (this.vg_locale === 'fr') {
        newLocale = 'en';
        this.va_changeLocale(newLocale);
      }
    },
  },
};
</script>

<style>
</style>
