<template>
  <div>
    <div class="msg-wrapper">
      <p class="note">{{ $t("desktop_best_experienced_on_mobile") }}</p>
    </div>
    <section class="pg-wrapper">
      <!-- main content start -->
      <div class="main-content-wrapper">
        <!-- container start-->
        <div class="container">
          <div class="columns">
            <div class="column">
              <!-- Pin Block Start -->
              <div class="wrapper">
                <i18n
                  path="how_to_redeem_info"
                  tag="div"
                  class="info_text"
                  style="width: 100%; max-width: 350px"
                >
                  <br />
                  <br />
                </i18n>
                <span
                  class="button is-primary mb20 pointer"
                  @click="goToCodes"
                  >{{ $t("view_codes") }}</span
                >
              </div>
              <!-- <div style="margin: auto 20px"> -->

              <!-- </div> -->
              <!-- Pin Block End -->
            </div>
          </div>
        </div>
        <!-- container end-->
      </div>
      <!-- main content end -->
    </section>
  </div>
</template>

<script>
export default {
  methods: {
    goToCodes() {
      this.$router.push({ name: 'Code Collection' });
    },
  },
};
</script>

<style scoped>
.info_text {
  left: 50%;
  top: 50%;
  margin: auto auto 40px;
  width: 70%;
  font-size: 22px;
  font-family: ProximaNova-ExtrabldIt, sans-serif;
  font-weight: 800;
  font-style: italic;
  line-height: 22px;
  text-transform: uppercase;
}
</style>
