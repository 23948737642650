import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';


import monster from '@/store/modules/monster';
import localization from '@/store/modules/localization';


Vue.use(Vuex);
const initialState = {
  monster: monster.state,
};
export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    monster,
    localization,
  },
  mutations: {
    clear_state(state) {
      monster.state.token = '';
      Object.assign(state, initialState);
    },
  },
});
