<template>
  <div>
    <div class="msg-wrapper">
      <p class="note">{{$t('desktop_best_experienced_on_mobile')}}</p>
    </div>
    <section class="pg-wrapper">
      <!-- main content start -->
      <div class="main-content-wrapper" v-if="!loading">
        <!-- container start-->
        <div class="container">
          <div class="columns">
            <div class="column">
              <!-- Pin Block Start -->
              <div class="wrapper">
                <div v-if="codes.length">
                  <div v-for="code in codes" :key="code.price_value">
                    <div class="mb20">
                    <div class="collection-title">{{code.prize_name}}</div>
                    <div class="collection-subtitle">{{code.prize_value}}</div>
                  </div>
                  </div>
                </div>
                <template v-else>
                  <i18n path="user_no_code" tag="div" class="user-no-code" >
                    <br/> <br/>
                  </i18n>
                </template>
              </div>
              <!-- Pin Block End -->
            </div>
          </div>
        </div>
        <!-- container end-->
      </div>
      <div v-else>
        {{$t('loading_please_wait')}}
      </div>
      <!-- main content end -->
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      codes: 'monster/prizesCodes',
    }),
  },

  mounted() {
    this.fetchCodes();
  },

  methods: {
    ...mapActions({
      getCodes: 'monster/GetMyCodes',
    }),

    async fetchCodes() {
      this.loading = true;
      try {
        const payload = await this.getCodes();
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.user-no-code {
  left: 50%;
  top: 50%;
  margin: 80px auto 0;
  width: 70%;
  font-size: 22px;
  font-family: ProximaNova-ExtrabldIt, sans-serif;
  font-weight: 800;
  font-style: italic;
  line-height: 30px;
  text-transform: uppercase;
}
</style>
