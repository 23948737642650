import { render, staticRenderFns } from "./codeCollection.vue?vue&type=template&id=038b531d&scoped=true&"
import script from "./codeCollection.vue?vue&type=script&lang=js&"
export * from "./codeCollection.vue?vue&type=script&lang=js&"
import style0 from "./codeCollection.vue?vue&type=style&index=0&id=038b531d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "038b531d",
  null
  
)

export default component.exports