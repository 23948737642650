var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"msg-wrapper"},[_c('p',{staticClass:"note"},[_vm._v(_vm._s(_vm.$t("desktop_best_experienced_on_mobile")))])]),_c('section',{staticClass:"pg-wrapper"},[_c('header',{staticClass:"main-header mb40",class:{
        'has-no-strip justify-content-between align-items-center':
          _vm.$route.name === 'Landing Page',
      }},[(
          _vm.$route.name === 'Landing Page' ||
          _vm.$route.name === 'Redeem Instruction' ||
          _vm.$route.name === 'Code Collection'
        )?_c('nav',{staticClass:"main-menu navbar"},[_c('div',{staticClass:"navbar-brand",on:{"click":function($event){$event.preventDefault();return _vm.switchNav($event)}}},[_c('span',{staticClass:"navbar-burger burger",class:{ 'is-active': _vm.navStatus },attrs:{"data-target":"navbarMenuHeroA"}},[_c('span'),_c('span'),_c('span')])]),_c('div',{staticClass:"navbar-menu",class:{ 'is-active': _vm.navStatus },attrs:{"id":"navbarMenuHeroA"}},[_c('div',{staticClass:"navbar-end fullscreen relative"},[_c('div',{staticClass:"navbar-brand mb20",on:{"click":function($event){$event.preventDefault();return _vm.switchNav($event)}}},[_c('span',{staticClass:"navbar-burger burger",class:{ 'is-active': _vm.navStatus },attrs:{"data-target":"navbarMenuHeroA"}},[_c('span'),_c('span'),_c('span')])]),_c('span',{staticClass:"navbar-item mb10 pointer",class:{ 'is-active': _vm.$route.name === 'Redeem Instruction' },on:{"click":function () { return _vm.changePath('Redeem Instruction'); }}},[_vm._v(" "+_vm._s(_vm.$t("how_to_redeem_text")))]),_c('span',{staticClass:"navbar-item mb10 pointer",on:{"click":_vm.changeLocale}},[_vm._v(" "+_vm._s(_vm.$t("nav_change_language")))]),_c('span',{staticClass:"navbar-item pointer",on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$t("nav_logout_btn"))+" ")])])])]):_vm._e(),(_vm.$route.name === 'Code Collection')?_c('div',{staticClass:"pg-title"},[_c('h1',[_vm._v(_vm._s(_vm.$t("nav_your_codes")))])]):_vm._e(),(_vm.$route.name === 'Redeem Instruction')?_c('div',{staticClass:"pg-title"},[_c('h1',[_vm._v(_vm._s(_vm.$t("how_to_redeem_text")))])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }