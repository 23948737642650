/* eslint-disable indent */

export default {
    updateLocale(state, newLocale) {
        state.selectedLocale = newLocale;
    },
    setLocales(state, locales) {
        state.locales = locales;
    },
    setTranslations(state, translations) {
        state.translations = translations;
    },
};
