<template>
  <div>
    <div class="msg-wrapper">
      <p class="note">{{$t('desktop_best_experienced_on_mobile')}}</p>
    </div>
    <section class="pg-wrapper">
      <div>
        <div class="logo_image">
          <img
            src="@/assets/img/monster-energy-logo.png"
            alt="Monster Energy Logo"
            class="mb40 image"
          />
        </div>
      </div>
      <!-- main content start -->
      <div class="main-content-wrapper">
        <!-- container start-->
        <div class="container">
          <div class="columns">
            <div class="column">
              <!-- Login Form Start -->
              <div class="wrapper">
                <div class="block-title mb20">{{$t('login_page_header')}}</div>
                <div class="field mb20">
                  <div class="control">
                    <!-- add has-value class to input for label effect -->
                    <input
                      ref="phoneNumber"
                      @blur="$v.phoneNumber.$touch()"
                      required
                      v-model="phoneNumber"
                      type="tel"
                      placeholder
                      :class="{'input': true,
                      'defaultCase': true,
                      'has-value': phoneNumber
                      }"
                      @keypress.enter="login()"
                    />
                    <label class="label">
                      {{$t('login_phone_number')}}
                      <span class="hide">r</span>
                    </label>
                  </div>
                  <div>
                    <transition name>
                      <template v-if="$v.phoneNumber.$dirty">
                        <small
                          v-if="!$v.phoneNumber.required"
                          :class="{'error': true}"
                        >{{$t('phoneNumber_required')}}</small>
                      </template>
                    </transition>
                  </div>
                </div>
                <div>
                  <small class="error">{{error.toUpperCase()}}</small>
                </div>

                <span
                  class="button is-primary mb20"
                  :class="{'is-loading': loading}"
                  @click="login()"
                >{{$t('login_button')}}</span>

                <div v-if="!fieldEmpty" class="mb20">
                  <p class="success">
                    <i>{{$t(message)}}</i>
                  </p>
                </div>
              </div>

              <!-- Login Form End -->
            </div>
          </div>
        </div>
        <!-- container end-->
      </div>
      <!-- main content end -->
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

function initialState() {
  return {
    phoneNumber: '',
    error: '',
    loading: false,
    message: '',
  };
}
export default {
  data() {
    return initialState();
  },

  validations: {
    phoneNumber: {
      required,
    },
  },

  computed: {
    ...mapGetters({
      vg_locale: 'localization/selectedLocale',
    }),

    fieldEmpty() {
      if (!this.$v.phoneNumber.required) {
        return true;
      }
      return false;
    },
  },

  watch: {
    fieldEmpty() {
      if (this.fieldEmpty === true) {
        this.error = '';
      }
    },
  },

  mounted() {
    this.$refs.phoneNumber.focus();
  },

  methods: {
    ...mapActions({
      va_login: 'monster/GetVerificationCode',
    }),
    async login() {
      this.loading = true;
      if (!this.$v.$invalid) {
        const data = { phone_number: this.phoneNumber };
        try {
          await this.va_login(data);
          this.message = 'access_code_sent';
          setTimeout(() => {
            this.$router.push({ name: 'Verify Code' });
          }, 2000);
        } catch (error) {
          this.error = error.response.data.msg;
        }
      } else {
        this.$v.phoneNumber.$touch();
      }
      this.loading = false;
    },

    changeLocale() {
      let newLocale;
      if (this.vg_locale === 'en') {
        newLocale = 'fr';
        this.va_changeLocale(newLocale);
      } else if (this.vg_locale === 'fr') {
        newLocale = 'en';
        this.va_changeLocale(newLocale);
      }
    },
  },
};
</script>

<style>
</style>
