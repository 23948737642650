/* eslint-disable no-plusplus */
/* eslint-disable indent */
import * as types from '@/store/mutation-types';

export default {
    [types.SET_USER](state, payload) {
        state.user = payload.consumer;
        state.token = payload['auth-token'];
    },
    [types.SET_PRIZES_CODES](state, payload) {
        state.prizesCodes = payload;
    },
};
