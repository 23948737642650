/* eslint-disable indent */
import axios from 'axios';
import { URL, apiToken, authToken } from './monster.env';

const authAxiosInstaceV1 = axios.create({
    baseURL: `${URL}/api/v1`,
    headers: {
        'api-token': apiToken,
    },
});

export default {
    /**
     * Login user with phone number to get verification
     * @param {string} [data={ 'phone_number': ''}]
     * @returns {Promise}
     */
    loginWithPhone(data) {
        return authAxiosInstaceV1({
            method: 'post',
            data,
            url: '/auth/login/phone',
        });
    },

    /**
     * Verify access code received in your cell
     * @param {string} [data={ 'access_code': ''}]
     * @returns {Promise}
     */
    verifyAccessCode(data) {
        return authAxiosInstaceV1({
            method: 'post',
            data,
            url: '/auth/login/phone/verify',
        });
    },

    getMyCodes() {
        return authAxiosInstaceV1({
            method: 'get',
            url: '/consumers/prizes/halo',
            headers: {
                'auth-token': authToken(),
            },
        });
    },


    logout() {
        return authAxiosInstaceV1({
            method: 'post',
            url: '/auth/logout',
            headers: {
                'auth-token': authToken(),
            },
        });
    },


};
