/* eslint-disable indent */
import * as types from '@/store/mutation-types';
import MonsterAPI from '@/clients/monster/monster.api';

export default {
    async GetVerificationCode({ commit }, data) {
        const payload = await MonsterAPI.loginWithPhone(data);
        return payload;
    },

    async VerifyAccessCode({ commit }, value) {
        const { data: { payload } } = await MonsterAPI.verifyAccessCode(value);
        commit(types.SET_USER, payload);
        return payload;
    },

    async GetMyCodes({ commit }, value) {
        const { data: { payload } } = await MonsterAPI.getMyCodes(value);
        commit(types.SET_PRIZES_CODES, payload);
        return payload;
    },

    async Logout() {
        await MonsterAPI.logout();
    },
};
